$(document).on('turbolinks:before-cache', function () {
  $('.select2').select2('destroy');
  $('.modal .select2').select2('destroy');
});

$(document).on('ready turbolinks:load', function() { 

  $('.checkOther').on('change', function() {
    var target = $(this).data('other-wrapper'),
        value  = $(this).val(),
        display_wrapper = (this.checked) && (value == 'other' || value == 'Other');

    if (display_wrapper) {
      $(target).removeClass('d-none');
      $(target).slideDown();
    } else {
      $(target).slideUp();
    }
  });

  setTimeout(function() {
    $('.temp-alerts').slideUp();
  }, 3000);

  $('form').on('click', '.add_fields', function(event) {
    event.preventDefault();
    
    var regexp, time;

    time   = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');
    $(this).before($(this).data('fields').replace(regexp, time));

  });
  
  tinymce.init({
    selector: '.tinymce',
    height: 250,
    menubar: false,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
      toolbar: 'undo redo | formatselect | ' +
      ' bold italic backcolor | alignleft aligncenter ' +
      ' alignright alignjustify | bullist numlist outdent indent | ' +
      ' removeformat | help'
  });

  $( ".select2" ).select2({
    theme: 'bootstrap-5'
  });
  
  const canvas       = document.querySelector("canvas");

  if (canvas != null) {
    const signaturePad = new SignaturePad(canvas);

    $('#signature-pad').on('click', function(event){
      event.preventDefault();

      if (signaturePad.isEmpty()) {
        return alert("Please provide a signature first.");
      }
    
      var data = signaturePad.toDataURL('image/svg+xml');
      
      $('#signature').val(data)
    });

    $('.signature-clear').on('click', function(event){
      event.preventDefault();
    
      signaturePad.clear();
      $('#signature').val(null);
    });
  }

  $(".inputInsert-trigger").on("keyup", function(event){
    var target      = $(this).data('input-insert-target'),
        placeholder = $(this).data('input-insert-placeholder'),
        new_value   = $(this).val();

    if (new_value == '') {
      $(target).html(placeholder);
    } else {
      $(target).html(new_value);
    }
  }).keyup();
  
  $('.input_expand').on('change', function() {
    var current_selection = $(this).val(),
        expandable_id     = $(this).data('input-expandable-id');

    if (current_selection == '') {
      $(expandable_id).removeClass('d-none');
    } else {
      $(expandable_id).addClass('d-none');
    }
  }).change();

  function copyClipboard(str) {
    const el = document.createElement('textarea');
    el.value = str;

    navigator.clipboard.writeText(str);

    console.log("Copied to Clipboard: " + str)

  };

  $('.trigger-copy').on('click', function() {
    event.preventDefault()

    var parent      = $(this).closest('.copy-wrapper'),
        input_value = $('input', parent).val(),
        button      = $('.btn', parent),
        initial_html = button.html();

    copyClipboard(input_value)

    button.html('Copied to Clipboard!');

    setTimeout(function() {
      button.html(initial_html);
    }, 3000);
  });
  
  $('input.currency-input').on('keyup', function(event) {
    // skip for arrow keys
    if(event.which >= 37 && event.which <= 40){
      event.preventDefault();
    }

    var $this = $(this);
    var num   = $this.val().split(".")[0].toString().replace(/[^0-9]/g, '');
    var num2  = num.replace(/,/gi, "");
  	var num3  = num2.split(/(?=(?:\d{3})+$)/).join(",");
    
    // the following line has been simplified. Revision history contains original.
    $this.val(num3);
  }).trigger('keyup');

  $('form').one('submit', function(e) {
    e.preventDefault();

    $('.currency-input').each(function() {
      var clean = $(this).val().split(",").join("")

      $(this).val(clean);
    });

    $(this).trigger("submit");
  });

  $('.toggle-trigger').on('click', function(event){
    event.preventDefault();
    target = $(this).data('toggleable');

    if ($(target).hasClass('d-none')) {
      $(target).removeClass('d-none');
    } else {
      $(target).toggle();
    }
  });

  $('form').on('click', '.remove_fields', function(event) {
    $(this).prev('input[type=hidden]').val('1');
    $(this).closest('fieldset').hide();
    return event.preventDefault();
  });
});
